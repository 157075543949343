<template>
  <!-- tmp ; to be removed once IntegNeo is available on Dev05 -->
  <div
    v-if="isSandboxOrLocal"
    class="salesforce-env"
  >
    <fieldset>
      <legend>Salesforce environment</legend>
      <div @click="toggleNewOffer">
        <input
          id="dev05"
          type="radio"
          name="environment"
          value="dev05"
          :checked="!isNewOffer"
        >
        <label for="dev05">Dev05</label>
      </div>

      <div @click="toggleNewOffer">
        <input
          id="integneo"
          type="radio"
          name="environment"
          value="integneo"
          :checked="isNewOffer"
        >
        <label for="integneo">IntegNeo</label>
      </div>
    </fieldset>
  </div>
</template>

<script>
/**
 * The OPS team implemented the new offer project on a new environment.
 * This method is a temporary solution to handle the new environment
 * until the OPS team implements the new offer project on the production environment.
 */
export default {
  name: 'SalesforceEnvironment',
  data() {
    return {
      isNewOffer: localStorage.getItem('is-new-offer') === 'true',
      isSandboxOrLocal: window.location.hostname === 'onboarding.sandbox.skello.io' || window.location.hostname === 'localhost',
    };
  },
  methods: {
    /**
       * The OPS team implemented the new offer project on a new environment.
       * This method is a temporary solution to handle the new environment
       * until the OPS team implements the new offer project on the production environment.
       */
    toggleNewOffer() {
      if (this.isNewOffer) {
        localStorage.removeItem('is-new-offer');
      } else {
        localStorage.setItem('is-new-offer', 'true');
      }

      // refresh the page
      window.location.reload();
    },
  },
};
</script>

<style lang="scss">
  .salesforce-env {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    fieldset {
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 10px;
      legend {
        font-size: 1.2em;
        font-weight: bold;
      }
      div {
        display: flex;
        align-items: center;
        input {
          margin-right: 5px;
        }
      }
    }
  }
</style>
