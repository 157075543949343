import axios from 'axios';
import { VUE_APP_SVC_FEATURE_FLAG_URL } from '@/shared/config';

export default class FeatureFlagClient {
  constructor() {
    this.client = axios.create({
      baseURL: VUE_APP_SVC_FEATURE_FLAG_URL,
      headers: {
        Accept: 'application/json',
        'X-Source-Client': 'skelloOnboarding',
      },
      timeout: 5000,
    });
  }

  async fetchFeatures() {
    return this.client.get('/features');
  }
}
