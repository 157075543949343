<template>
  <div class="quote-details__wrapper">
    <div class="quote-table quote-table--white">
      <div class="quote-table__title">
        <div>
          <div class="quote-table__title__left quote-table__title__orga-name">
            {{ quote.organisation.name }}
          </div>
          <div>
            <span class="quote-table__subtitle__left">
              {{ $t('quote_details.commitment.label') }}
            </span>
            <span class="quote-table__subtitle__left text--black">
              {{ $t(`quote_details.commitment.${quoteCommitment}`) }}
            </span>

            <span class="quote-table__subtitle__left billing-cycle__text">
              {{ $t('quote_details.billing_cycle.label') }}
            </span>
            <span class="quote-table__subtitle__left text--black">
              {{ $t(`quote_details.billing_cycle.${quoteBillingCycle}`) }}
            </span>
          </div>
        </div>
        <div>
          <span class="quote-table__title__right">
            {{ $t('quote_details.quote_number') }}
          </span>
          <span class="quote-table__title__right text--black">
            #{{ quote.quote_number }}
          </span>
        </div>
      </div>
      <div class="quote-table__content">
        <table
          v-if="showMonthlyTable"
          class="quote-table__details"
        >
          <thead class="quote-table__details__headers">
            <tr class="quote-table__row quote-table__row--header">
              <th class="quote-table__header quote-table__header--left">
                {{ $t('quote_details.headers.description') }}
              </th>
              <th
                v-if="isCatalogVersionV2"
                class="quote-table__header"
              >
                {{ $t('quote_details.headers.max_users') }}
              </th>
              <th class="quote-table__header">
                {{ $t('quote_details.headers.quantity') }}
              </th>
              <th class="quote-table__header">
                {{ $t('quote_details.headers.unit_price') }}
              </th>
              <th class="quote-table__header">
                {{ $t('quote_details.headers.total') }}
              </th>
            </tr>
          </thead>
          <colgroup>
            <col
              v-if="isCatalogVersionV2"
              span="1"
            >
            <col
              span="1"
            >
            <col
              span="1"
            >
            <col
              span="1"
            >
          </colgroup>
          <tbody class="quote-table__details__content">
            <template
              v-for="line, index in monthlyQuote.lines"
            >
              <CollapseLine
                :key="index"
                :line="line"
              />
            </template>
          </tbody>
        </table>
        <div class="separator" />
        <div
          v-if="showFirstMonthTable"
          class="quote-table__title__first-month"
        >
          <div class="quote-table__title__first-month__left">
            {{ $t('quote_details.first_month_title') }}
          </div>
          <div>
            <table
              class="quote-table__details"
            >
              <colgroup>
                <col
                  span="1"
                >
                <col
                  span="1"
                >
                <col
                  span="1"
                >
              </colgroup>
              <tbody class="quote-table__details__content">
                <tr
                  v-for="line, index in firstMonthQuote.lines"
                  :key="index"
                  class="quote-table__row"
                >
                  <td class="quote-table__cell quote-table__cell--item-name">
                    {{ line.name }}
                  </td>
                  <td class="quote-table__cell">
                    {{ line.quantity }}
                  </td>
                  <td class="quote-table__cell">
                    {{ line.unitPrice }}
                  </td>
                  <td class="quote-table__cell">
                    {{ line.totalPrice }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="quote-table__totals-content">
          <div class="quote-table__totals">
            <div
              v-for="line, index in monthlyQuote.totals"
              :key="index"
              class="quote-table__total-line"
            >
              <div class="quote-table__total-title">
                {{ line.name }}
              </div>
              <div class="quote-table__total-value">
                {{ line.price }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="showFirstMonthTable"
          class="quote-table__totals-content"
        >
          <div class="quote-table__totals">
            <div
              v-for="line, index in firstMonthQuote.totals"
              :key="index"
              class="quote-table__total-line"
            >
              <div class="quote-table__total-title">
                {{ line.name }}
              </div>
              <div class="quote-table__total-value">
                {{ line.price }}
              </div>
            </div>
          </div>
        </div>
        <div class="quote-table__total-line__final-price">
          <div class="quote-table__total-title">
            {{ monthlyQuote.finalPrice.name }}
          </div>
          <div class="quote-table__total-value">
            {{ monthlyQuote.finalPrice.price }}
          </div>
        </div>
        <div
          v-if="showFirstMonthTable"
          class="quote-table__total-line__final-price__first-month"
        >
          <div class="quote-table__total-title">
            {{ firstMonthQuote.finalPrice.name }}
          </div>
          <div class="quote-table__total-value">
            {{ firstMonthQuote.finalPrice.price }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';

import CollapseLine from '@components/CollapseLine';
import {
  extractShopsNames, extractPrice,
} from '@/shared/utils/method_helper';

export default {
  components: {
    CollapseLine,
  },
  computed: {
    ...mapState(['quote']),
    ...mapGetters(['isContractChange', 'isCatalogVersionV2']),

    // There is no simple way to mark a quote as yearly or monthly
    // so we return 'yearly' if we can find an item in the quote that needs to be paid yearly
    // else, return monthly by default
    quoteBillingCycle() {
      if (this.isCatalogVersionV2) {
        const firstShopPaymentFrequency =
          this.quote.organisation.shops[0].contract_terms.payment_frequency;
        const uniqPaymentFrequency =
          this.quote.organisation.shops.every(
            shop => shop.contract_terms.payment_frequency === firstShopPaymentFrequency,
          );

        return uniqPaymentFrequency ? firstShopPaymentFrequency.toLowerCase() : 'multiple';
      }

      return this.quote.quote_line_items.find(item => item.type !== 'Charge').billing_cycle_unit;
    },
    quoteCommitment() {
      if (this.isCatalogVersionV2) {
        const firstShopCommitment = this.quote.organisation.shops[0].contract_terms.commitment;
        const uniqCommitment =
          this.quote.organisation.shops.every(
            shop => shop.contract_terms.commitment === firstShopCommitment,
          );

        return uniqCommitment ? firstShopCommitment.toLowerCase() : 'multiple';
      }

      return this.quote.contract_terms.total_engagement_unit.toLowerCase();
    },
    showMonthlyTable() {
      return this.monthlyQuote.lines.length > 0;
    },
    showFirstMonthTable() {
      return this.firstMonthQuote.lines.length > 0;
    },
    monthlyQuote() {
      const monthlyItems = this.filterQuoteLine(lineItem => lineItem.type !== 'Charge');
      const monthlyLines = this.serializeLines(monthlyItems);

      const totals = [
        {
          name: this.$t('quote_details.totals.total_amount'),
          subtext: this.$t('quote_details.totals.ex_vat'),
          price: extractPrice(this.quote, this.quote.totals.total_amount_with_discount),
        },
        {
          name: this.$t('quote_details.totals.total_tax'),
          subtext: '',
          price: extractPrice(this.quote, this.quote.totals.total_tax),
        },
      ];

      return {
        lines: monthlyLines,
        totals,
        finalPrice: {
          name: this.$t('quote_details.totals.total_amount_with_tax'),
          price: extractPrice(this.quote, this.quote.totals.total_amount_with_tax),
        },
      };
    },
    firstMonthQuote() {
      const firstMonthItems = this.filterQuoteLine(lineItem => lineItem.type === 'Charge');
      const firstMonthLines = this.serializeLines(firstMonthItems);

      const totals = [
        {
          name: this.$t('quote_details.totals.total_amount_first_payment'),
          subtext: this.$t('quote_details.totals.ex_vat'),
          price: extractPrice(this.quote, this.quote.totals.total_amount_first_payment),
        },
        {
          name: this.$t('quote_details.totals.total_tax_first_payment'),
          subtext: '',
          price: extractPrice(this.quote, this.quote.totals.total_tax_first_payment),
        },
      ];

      return {
        lines: firstMonthLines,
        totals,
        finalPrice: {
          name: this.$t('quote_details.totals.total_amount_first_payment_with_tax'),
          price: extractPrice(this.quote, this.quote.totals.total_amount_first_payment_with_tax),
        },
      };
    },
  },
  methods: {
    filterQuoteLine(filterCallback) {
      return this.quote.quote_line_items.filter(
        this.isContractChange ?
          lineItem => filterCallback(lineItem) && lineItem.status === 'Added' :
          filterCallback,
      );
    },
    serializeLines(lineItems) {
      const consolidatedLineItems = {};

      lineItems.forEach(lineItem => {
        const existingLineItem = consolidatedLineItems[`${lineItem.title}--${lineItem.total_amount_with_discount}`];

        if (existingLineItem) {
          existingLineItem.quantity += lineItem.quantity;
          existingLineItem.total_amount_with_discount += lineItem.total_amount_with_discount;
          existingLineItem.quote_line_item_shops.push(...lineItem.quote_line_item_shops);
        } else {
          consolidatedLineItems[`${lineItem.title}--${lineItem.total_amount_with_discount}`] = lineItem;
        }
      });

      return Object.values(consolidatedLineItems).map(lineItem => ({
        name: lineItem.title,
        maxUsers: lineItem.max_users,
        quantity: lineItem.quantity,
        unitPrice: extractPrice(this.quote, lineItem.unit_amount),
        totalPrice: extractPrice(this.quote, lineItem.total_amount_with_discount),
        shopsNames: extractShopsNames(lineItem, this.quote),
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.quote-table  {
  border-radius: 8px;
  margin-bottom: 24px;
}

.quote-table--white {
  border: 1px solid $sk-grey-10;
}

.quote-table--grey {
  background: rgba(217, 217, 217, .2);
}

.quote-table__title {
  border-bottom: 1px solid $sk-grey-10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 15px 24px;
}

.quote-table__title__first-month {
  border-bottom: 1px solid $sk-grey-10;
}

.quote-table__title__first-month__left {
  padding: 24px 0px;
  font-size: $fs-text-l;
  font-weight: $fw-semi-bold;
}

.quote-table__title__left {
  line-height: 22px;
  font-size: $fs-heading-s;
  font-weight: $fw-semi-bold;
}

.quote-table__title__right {
  color: $sk-grey-50;
}

.quote-table__title__orga-name {
  margin-bottom: 4px;
}

.quote-table__subtitle__left {
  font-size: $fs-text-m;
  font-weight: $fw-regular;
  color: $sk-grey-50;
}

.billing-cycle__text {
  margin-left: 12px;
}

.text--black {
  color: $sk-black;
}

.quote-table--grey .quote-table__title__left {
  font-size: 16px;
}

.quote-table__content {
  padding: 0px 24px 24px;
}

.quote-table__details {
  table-layout: fixed;
  width: 100%;
  text-align: right;
  margin-bottom: 16px;
}

.quote-table__header  {
  font-size: $fs-text-s;
  font-weight: $fw-medium;
  color: $sk-grey-50;
  padding-bottom: 24px;
}

.quote-table__header--left {
  text-align: left;
  color: $sk-grey-50;
  font-size: $fs-text-s;
}

.quote-table__cell {
  line-height: 16px;
  padding: 0px 0px 12px 0px;
}

.quote-table__cell--item-name {
  text-align: left;
}

.quote-table__totals-content {
  padding-top: 24px;
}

.quote-table__totals {
  line-height: 15px;
  font-size: $fs-text-m;
  color: $sk-grey-50;
}

.quote-table__total-line {
  display: flex;
  justify-content: space-between;
}

.quote-table__total-line:nth-of-type(1) {
  margin-bottom: 8px;
}

.quote-table__total__subtext {
  color: $sk-grey-50;
}

.quote-table__total-line__final-price {
  margin-top: 24px;
  line-height: 22px;
  font-weight: $fw-semi-bold;
  font-size: $fs-heading-s;
  display: flex;
  justify-content: space-between;
}

.quote-table__total-line__final-price__first-month {
  margin-top: 12px;
  line-height: 18px;
  font-size: $fs-text-l;
  display: flex;
  justify-content: space-between;
}

.separator {
  border-bottom: 1px solid $sk-grey-10;
}

col {
  width: 15%;

  &:first-child {
    width: 40%;
  }
}
</style>

<style lang="scss">
.collapse-line:not(:last-of-type) > .collapse-line__cell {
  padding-bottom: 12px;
}
</style>
